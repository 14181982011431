




















import NotificationPopupContentMixin from '@/components/notification-popup/NotificationPopupContentMixin';

export default {
  name: 'NotificationPopupSuccess',
  mixins: [NotificationPopupContentMixin],
  methods: {
    handleLink(): void {
      this.handleClose();
      setTimeout(() => this.$router.push({ path: 'loyalty-promotion' }), 300);
    }
  }
};
