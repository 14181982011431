import { EAccountNotification } from '@/api/schema';

export default {
  methods: {
    handleClose(): void {
      this.$emit('close');
    },
    handleNext(next: EAccountNotification): void {
      this.handleClose();

      if (next) {
        setTimeout(() => this.$emit('next', next), 300);
      }
    }
  }
};
